import React from 'react';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Button, Modal } from 'antd';
import { getChildrenToRender } from '@/utils/utils';
import { findDOMNode } from 'react-dom';

class Part0 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleCancel = () => {
    const video = findDOMNode(this.videoRef);
    video.pause();
    this.setState({
      visible: false,
    });
  };

  render() {
    const { ...tagProps } = this.props;
    const { dataSource, } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    const videoChildren = dataSource.video.children.video;
    const videoNameArray = videoChildren.split('.');
    const type = videoNameArray[videoNameArray.length - 1];
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}

          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
            <Button onClick={
              this.showModal
            }>观看介绍</Button>
          </QueueAnim>

          <OverPack {...dataSource.OverPack}>
            <TweenOne
              key="video"
              animation={{ ...animType.one, delay: 300 }}
              {...dataSource.image}
            >

              <img src={dataSource.image.children} width="100%" alt="img" />
            </TweenOne>
          </OverPack>

        </div>

        <Modal
          visible={this.state.visible}
          footer={null}
          closable={false}
          bodyStyle={{ padding: 0, lineHeight: 0 }}
          width={800}
          onCancel={this.handleCancel}
        >
          <video
            width="100%"
            autoPlay
            controls
            ref={(ref)=>{this.videoRef = ref}}
          >
            <source src={videoChildren} type={`video/${type}`} />
            <track kind="captions" />
          </video>
        </Modal>

      </div>
    );
  }
}

export default Part0;
