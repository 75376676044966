import React from 'react';
import QueueAnim from 'rc-queue-anim';

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource,isMobile } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      
      <div {...currentProps} {...dataSource.wrapper}>
        {/* <video autoPlay loop muted>
          <source src={homeVideo} type='video/mp4' />
      </video> */}
          <img src={dataSource.img.children} width={isMobile ? '100%':'68%'} alt="img" />

         <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
            
          <div key="title" {...dataSource.img}>
              {/* <img src={dataSource.img.children} width="100%" alt="img" /> */}
          </div>
          <div key="content" {...dataSource.content}>
            {dataSource.content.children}
          </div>
          {/* <Button ghost key="button" {...dataSource.button}>
            {dataSource.button.children}
          </Button> */}
        </QueueAnim> 
      </div>
    );
  }
}
export default Banner;
