import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';

class Part2 extends React.PureComponent {
  
  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
         <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.page}
        >
          <div key="title" {...dataSource.content}>
            { (
                dataSource.content.children
              )}
          </div>

        </QueueAnim> 
        
        <OverPack {...dataSource.OverPack}>
            <TweenOne
              key="image-l"
              animation={{ ...animType.one, delay: 300 }}
              {...dataSource.imageL}
            >
              
              <img src={dataSource.imageL.children} width="100%" alt="img" />
            </TweenOne>
            
            <TweenOne
              key="image-r"
              animation={{ ...animType.one, delay: 300 }}
              {...dataSource.imageR}
            >
              
              <img src={dataSource.imageR.children} width="100%" alt="img" />
            </TweenOne>
          </OverPack>
      </div>
    );
  }
}
export default Part2;
