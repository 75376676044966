import React from 'react';
export const Part1DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title',
    children: process.env.SOURCE_URL+'/pic-2.jpeg',
  },
  content: {
    className: 'banner0-content',
    children: (
      <div>
        <h1>「用AI改变生产方式」</h1>
        <h3>聚焦AI质/量检测项目的<span>整体解决方案提供商</span></h3>
      </div>
    ),
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};
export const Part5DataSource = {
  wrapper: { className: 'banner12-wrapper' },
  image: {
    className: 'banner12-img',
    children: process.env.SOURCE_URL+'/home-1-bg.png',
  },
  imageAbs:{
    className:'banner12-img-abs',
    children: process.env.SOURCE_URL+'/home-1.png',
  },
  titleAbs:{
    className:'banner12-title-abs',
    children:'数字化智能品控联合实验室'
  }
};
export const Part3DataSource = {
  wrapper: { className: 'banner2' },
  textWrapper: { className: 'banner2-text-wrapper' },
  img:{
    className:'banner2-img',
    children:process.env.SOURCE_URL+"/adv-main.jpeg"
  },
  content: {
    className: 'banner2-content',
    children: (
      <div>
        <h1>不止算法，我们是AI集成商</h1>
      </div>
    ),
  },
};
export const Part4DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '我们的优势' }],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
              process.env.SOURCE_URL+'/advantage-1.png',
          },
          title: { children: '丰富的用户资源' },
          content: { children: '具备庞大的工业制造业客户资源，直接对话最终用户，一线的用户需求及数据' },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
            process.env.SOURCE_URL+'/advantage-2.png',
          },
          title: { children: '自主研发的核心算法' },
          content: { children: '与东华大学深度合作，成立联合实验室，所有AI算法均为自主研发且保证算法持续更新迭代' },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'icon',
            children:
            process.env.SOURCE_URL+'/advantage-3.png',
          },
          title: { children: '强大的集成能力' },
          content: { children: '与多家重量级自动化伙伴成功合作多个落地案例及AI集成产品' },
        },
      },
    ],
  },
};

export const Part2DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams1-wrapper',
  },
  page: {
    className: 'home-page teams1',
  },
  OverPack: {
    playScale: 0.3,
    className: '',
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '产品系列',
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [

      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        href:'/canbeauty',
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: process.env.SOURCE_URL+'/canbeauty.png',

            },
            {
              name: 'title',
              className: 'teams1-title',
              children: 'AI+美妆',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: '针对多样化的美妆产品，对不同美妆类别定制视觉采集装置及AI识别算法，打造美妆专属的AI产品',
            },{
              name: 'inner',
              className:'inner-title',
              children:'CanBeauty系列'
            }
          ],
        },
      },
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        href:'/security',
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: process.env.SOURCE_URL+'/cansee.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: 'AI+万物识别',
            },
            
            {
              name: 'content1',
              className: 'teams1-content',
              children: '集成AI、自动化、机器人及无人机等高新技术，打造真正好用的AI识别产品',
            },
            {
              name: 'inner',
              className:'inner-title',
              children:'CanSee系列'
            }
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        href:'/energy',
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: process.env.SOURCE_URL+'/canenergy.png',

            },
            {
              name: 'title',
              className: 'teams1-title',
              children: 'AI+能源',
            },
            {
              name: 'content1',
              className: 'teams1-content',
              children: '利用AI等技术，助力新能源产品质检，并且从预测、监测、优化三个方面助力碳中和落地',
            },{
              name: 'inner',
              className:'inner-title',
              children:'CanEnergy系列'
            }
          ],
        },
      },
    ],
  },
}