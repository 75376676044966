import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { isImg } from '@/utils/utils';
import './footer2.less';

class Footer2 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <div {...props} {...dataSource.wrapper}>
        <OverPack {...dataSource.OverPack}>
          <TweenOne
            animation={{ x: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyright}
          >
            {dataSource.copyright.children.map((item, i) =>
              <a key={i.toString()} href={item.href} target="_blank" rel="noopener noreferrer">
                {
                  React.createElement(
                    item.name.indexOf('title') === 0 ? 'h1' : 'div',
                    {  ...item },
                    typeof item.children === 'string' && item.children.match(isImg)
                      ? React.createElement('img', {
                          src: item.children,
                          alt: 'img',
                        })
                      : item.children
                  )
                }
              </a>
            )}
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}

export default Footer2;
