import React from 'react';
import { enquireScreen } from 'enquire-js';


import Part1 from '@/components/textWrapper';
import Part2 from '@/components/bannerWrapper'
import Part3 from './Part3';
import Part4 from './Part4';
// import Part5 from './Part5';
import Part6 from './Part6';
// import Part7 from './Part7';
// import Part8 from './Part8';
// import Part9 from './Part9';
// import Part10 from '@/components/caseWrapper'


import {
    Part1DataSource,
    Part2DataSource,
    Part3DataSource,
    Part4DataSource,
    // Part5DataSource,
    Part6DataSource,
    // Part7DataSource,
    // Part8DataSource,
    // Part9DataSource,
    // Part10DataSource,
} from './data.source.js';

import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const location = window.location;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    window.scrollTo(0, 0)

    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Part1
        id="en-part1"
        key="en-part1"
        dataSource={Part1DataSource}
      />,
      <Part2
        id="en-part2"
        key="en-part2"
        dataSource={Part2DataSource}
      />,
      <Part3 
        id="en-part3"
        key="en-part3"
        dataSource={Part3DataSource}
      />,
      <Part4
        id="en-part4"
        key="en-part4"
        dataSource={Part4DataSource}
      />,
      // <Part5
      //   id="en-part5"
      //   key="en-part5"
      //   dataSource={Part5DataSource}
      // />,
      <Part6
        id="en-part6"
        key="en-part6"
        dataSource={Part6DataSource}
      />,
      // <Part7
      //   id="en-part7"
      //   key="en-part7"
      //   dataSource={Part7DataSource}
      // />,
      // <Part8
      //   id="en-part8"
      //   key="en-part8"
      //   dataSource={Part8DataSource}
      // />,
      // <Part9
      //   id="en-part9"
      //   key="en-part9"
      //   dataSource={Part9DataSource}
      // />,
      // <Part10
      //   id="en-part10"
      //   key="en-part10"
      //   dataSource={Part10DataSource}
      // />,
    ];
    return (
      <div
        className="energy-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
