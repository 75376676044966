
export const Part1DataSource = {
    wrapper: { className: ' security-part1-wrapper' },
    page: { className: 'security-part1' },
    OverPack: { playScale: 0.3, className: '' },
    childWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanSee For Intelligent Security ',
          className: 'title-h1',
        },
        {
          name: 'content',
          className: 'title-content security-part1-title-content',
          children: '提供可穿戴智慧安防解决方案',
        },
      ],
    },
    video: {
      className: 'lipstick-part0-video',
      children: {
        video: process.env.SOURCE_URL+'/security/video.mp4',
        image: process.env.SOURCE_URL+'/lipstick-img-1.png',
      },
    },
    image: {
      className: 'security-part1-image',
      children:
      process.env.SOURCE_URL+'/security/32.png',
    },

}


export const Part2DataSource = {
  wrapper: { className: 'home-page-wrapper security-part2-wrapper'},
  OverPack: { playScale: 0.3 },
  page: { className: 'home-page security-part2' },
  imageWrapper: { playScale: 0.3, className: 'security-part2-image-wrapper' },
  title: {
    className: 'security-part2-title',
    children:'产品功能',
  },
  block:{
    className:"security-part2-block-wrapper",
    children:[
      {
        name: 'block0',
        className: 'security-part2-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'security-part2-icon',
            children:
              process.env.SOURCE_URL+'/security/20.png',
          },
          textWrapper: { className: 'security-part2-text' },
          title: { className: 'security-part2-block-title', children: '人证识别' },
          content: { className: 'security-part2-block-content', children:'通过预先人脸信息录入,可以快速有效地分辨业主/访客/用户或外来人员,提高安防管控'},
        },
      },
      {
        name: 'block1',
        className: 'security-part2-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'security-part2-icon',
            children:
              process.env.SOURCE_URL+'/security/21.png',
          },
          textWrapper: { className: 'security-part2-text' },
          title: { className: 'security-part2-block-title', children: '车牌识别' },
          content: { className: 'security-part2-block-content', children:"匹配预先录入的车辆信息,提供车位信息,区分本小区车辆和外来车辆"},
        },
      },
      {
        name: 'block2',
        className: 'security-part2-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'security-part2-icon',
            children:
              process.env.SOURCE_URL+'/security/22.png',
          },
          textWrapper: { className: 'security-part2-text' },
          title: { className: 'security-part2-block-title', children: '视频直播' },
          content: { className: 'security-part2-block-content', children:"监控中心随时调用现场镜头的直播内容,进行查看,并一键转播至微信"},

        },
      },
      {
        name: 'block3',
        className: 'security-part2-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'security-part2-icon',
            children:
              process.env.SOURCE_URL+'/security/23.png',
          },
          textWrapper: { className: 'security-part2-text' },
          title: { className: 'security-part2-block-title', children: '巡更定位' },
          content: { className: 'security-part2-block-content', children:"通过蓝牙信标精准定位安防人员实时巡更位置"},

        },
      },
    ]
  },
}


export const Part3DataSource = {
  wrapper: { className: 'home-page-wrapper security-part3-wrapper' },
  page: { className: 'home-page security-part3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '可穿戴智能安防设备',
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'security-part3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'security-part3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'security-part3-icon',
            children:
              process.env.SOURCE_URL+'/security/24.png',
          },
          textWrapper: { className: 'security-part3-text' },
          title: { className: 'security-part3-title', children: '实时采集' },
          content: {
            className: 'security-part3-content',
            children:
              '现场佩戴智能摄像头,后端人员通过摄像头,采集现场动态画面',
          },
        },
      },
      {
        name: 'block1',
        className: 'security-part3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'security-part3-icon',
            children:
            process.env.SOURCE_URL+'/security/25.png',
          },
          textWrapper: { className: 'security-part3-text' },
          title: { className: 'security-part3-title', children: '自动对比' },
          content: {
            className: 'security-part3-content',
            children:
              '证件、车牌实时比对,实时音视频通信,自动回传、实时直播',
          },
        },
      },
      {
        name: 'block2',
        className: 'security-part3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'security-part3-icon',
            children:
            process.env.SOURCE_URL+'/security/26.png',
          },
          textWrapper: { className: 'security-part3-text' },
          title: { className: 'security-part3-title', children: '安全预警' },
          content: {
            className: 'security-part3-content',
            children:
              '视频静止立即报警,保障安保人员的个人安全',
          },
        },
      },
      {
        name: 'block3',
        className: 'security-part3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'security-part3-icon',
            children:
            process.env.SOURCE_URL+'/security/27.png',
          },
          textWrapper: { className: 'security-part3-text' },
          title: { className: 'security-part3-title', children: '智能存储' },
          content: {
            className: 'security-part3-content',
            children:
              '智能可视化指挥调度,智能自动存储、智能自动充电、开机即录',
          },
        },
      },
      {
        name: 'block4',
        className: 'security-part3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'security-part3-icon',
            children:
            process.env.SOURCE_URL+'/security/28.png',
          },
          textWrapper: { className: 'security-part3-text' },
          title: { className: 'security-part3-title', children: '数据安全' },
          content: {
            className: 'security-part3-content',
            children:
              '后台本地化部署，通过APP拍摄录制的视频都存储在眼镜中，手机中没有任何视频或照片，用于比对的人脸数据都是加密值',
          },
        },
      },
      {
        name: 'block5',
        className: 'security-part3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'security-part3-icon',
            children:
            process.env.SOURCE_URL+'/security/29.png',
          },
          textWrapper: { className: 'security-part3-text' },
          title: { className: 'security-part3-title', children: '灵活调度' },
          content: {
            className: 'security-part3-content',
            children:
              '了解员工的实时状态及位置，提供对话功能，优先指派就进人员处理突发事件。',
          },
        },
      },
    ],
  },
};


export const Part4DataSource = {
  wrapper: { className: 'home-page-wrapper security-part4-wrapper' },
  page: { className: 'home-page security-part4' },
  childWrapper: {
    className: 'security-part4-text-wrapper',
    children: [
      { name: 'title', children: '第一视角', className: 'security-part4-title' },
      { name: 'title', children: '头戴式智能眼镜', className: 'security-part4-explain' },
      {
        name: 'explain',
        className: 'security-part4-explain',
        children: '现场佩戴智能摄像头通过摄像头第一视角采集现场动态画面',
      },

    ],
  },
  image: {
    className: 'security-part4-image',
    children:
      process.env.SOURCE_URL+'/security/30.png',
  },
};

export const Part5DataSource = {
  wrapper: { className: 'home-page-wrapper security-part5-wrapper' },
  page: { className: 'home-page security-part5' },
  childWrapper: {
    className: 'security-part5-text-wrapper',
    children: [
      { name: 'title', children: '实时时间直播', className: 'security-part5-title' },
      {
        name: 'explain',
        className: 'security-part5-explain',
        children: '安保人员通过手机，及时发起直播，管理人员通过观看直播画面，实时指挥调度',
      },

    ],
  },
  image: {
    className: 'security-part5-image',
    children:
      process.env.SOURCE_URL+'/security/31.png',
  },
};

export const Part7DataSource = {
  wrapper: { className: 'home-page-wrapper csar-part7-wrapper'},
  OverPack: { playScale: 0.3 },
  page: { className: 'home-page csar-part7' },
  imageWrapper: { playScale: 0.3, className: 'csar-part7-image-wrapper' },
  title: {
    className: 'csar-part7-title',
    children:'应用场景扩展',
  },
  block:{
    className:"csar-part7-block-wrapper",
    children:[
      {
        name: 'block0',
        className: 'csar-part7-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part7-icon',
            children:
              process.env.SOURCE_URL+'/csar/10.png',
          },
          textWrapper: { className: 'csar-part7-text' },
          title: { className: 'csar-part7-block-title',children:[
            {
              name:'scene',
              className:'csar-part7-block-scene',
              children:'瓶底喷码自动识别'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'印刷材料识别校验'
            }
          ] },
        },
      },
      {
        name: 'block1',
        className: 'csar-part7-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part7-icon',
            children:
              process.env.SOURCE_URL+'/csar/11.png',
          },
          textWrapper: { className: 'csar-part7-text' },
          title: { className: 'csar-part7-block-title', children:[
            {
              name:'scene',
              className:'csar-part7-block-scene',
              children:'自动报关、退税'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'发票真伪验证'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'企业工商信息收集'
            }
          ]},
        },
      },
      {
        name: 'block2',
        className: 'csar-part7-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part7-icon',
            children:
              process.env.SOURCE_URL+'/csar/12.png',
          },
          textWrapper: { className: 'csar-part7-text' },
          title: { className: 'csar-part7-block-title', children:[
            {
              name:'scene',
              className:'csar-part7-block-scene',
              children:'订单识别倒入'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'SAP入库制单自动化'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'采购入库自动化'
            }
          ] },
        },
      },
      {
        name: 'block3',
        className: 'csar-part7-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part7-icon',
            children:
              process.env.SOURCE_URL+'/csar/13.png',
          },
          textWrapper: { className: 'csar-part7-text' },
          title: { className: 'csar-part7-block-title', children:[
            {
              name:'scene',
              className:'csar-part7-block-scene',
              children:'出车自动化'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'操作票自动开票'
            }
          ]},
        },
      },
    ]
  },
}
export const Part6dDataSource = {
  wrapper: { className: 'home-page-wrapper csar-part6-wrapper'},
  OverPack: { playScale: 0.3 },
  page: { className: 'home-page csar-part6' },
  imageWrapper: { playScale: 0.3, className: 'csar-part6-image-wrapper' },
  title: {
    className: 'csar-part6-title',
    children:'产品特性',
  },
  block:{
    className:"csar-part6-block-wrapper",
    children:[
      {
        name: 'block0',
        className: 'csar-part6-block',
        md: 12,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part6-icon',
            children:
              process.env.SOURCE_URL+'/csar/10.png',
          },
          textWrapper: { className: 'csar-part6-text' },
          title: { className: 'csar-part6-block-title',children:[
            {
              name:'header',
              className:'csar-part6-block-header',
              children:'识别率高'
            },{
              name:'text',
              className:'csar-part6-block-text',
              children:'采用了计算机视觉深度学习的方法和自然语言处理深度融合技术，形成完备的包含文字识别、字母识别以及符号识别的技术体系。中英文字符准确率达99%，识别准确度达较高水品'
            }
          ] },
        },
      },
      {
        name: 'block1',
        className: 'csar-part6-block',
        md: 12,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part6-icon',
            children:
              process.env.SOURCE_URL+'/csar/11.png',
          },
          textWrapper: { className: 'csar-part6-text' },
          title: { className: 'csar-part6-block-title', children:[
            {
              name:'header',
              className:'csar-part6-block-header',
              children:'可跨网站填报'
            },{
              name:'text',
              className:'csar-part6-block-text',
              children:'实现企业业务流程自动化和智能辅助，实现数据自动获得极大提高了业务运营效率，已成功用于材料夸网站填报，并有效降低文本处理流程中人为的错误风险，助理企业数字化升级'
            },
          ]},
        },
      },
      {
        name: 'block2',
        className: 'csar-part6-block',
        md: 12,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part6-icon',
            children:
              process.env.SOURCE_URL+'/csar/12.png',
          },
          textWrapper: { className: 'csar-part6-text' },
          title: { className: 'csar-part6-block-title', children:[
            {
              name:'header',
              className:'csar-part6-block-header',
              children:'文档差异对比'
            },{
              name:'text',
              className:'csar-part6-block-text',
              children:'采用自然语言处理技术、深度学习技术、计算机视觉技术实现自动抽取文档的关键信息，对比文档之间的差异、审核文档潜在风险、识别提取文档中的信息，赋能企业文档流程化、自动化、智能处理'
            }
          ] },
        },
      },
      {
        name: 'block3',
        className: 'csar-part6-block',
        md: 12,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part6-icon',
            children:
              process.env.SOURCE_URL+'/csar/13.png',
          },
          textWrapper: { className: 'csar-part6-text' },
          title: { className: 'csar-part6-block-title', children:[
            {
              name:'header',
              className:'csar-part6-block-header',
              children:'流程定制化、私有化部署'
            },{
              name:'text',
              className:'csar-part6-block-text',
              children:'部署至本地服务器，根据不同的业务流程，设计独特的定制化流程设计开发，并在用户局域网内实现文字识别功能，保障数据私密性。'
            }
          ]},
        },
      },
    ]
  },
}

  export const Part10DataSource = {
    wrapper: {
      className: 'case-wrapper-comp-wrapper',
    },
    page: {
      className: 'case-wrapper-comp',
    },
    OverPack: {
      playScale: 0.3,
      className: '',
    },
    titleWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanBeauty系列更多产品',
        },
      ],
    },
    block: {
      className: 'block-wrapper',
      children: [
        {
          name: 'block0',
          className: 'block',
          md: 8,
          href:'/lipstick',
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/lipstick-img-1.png',
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '口红产线质检',
              },
            ],
          },
        },
        {
          name: 'block1',
          className: 'block',
          md: 8,
          href:'/jetcode',
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/jetcode/1.png',

              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '瓶底喷码检测',
              },
            ],
          },
        },
        {
          name: 'block2',
          className: 'block',
          md: 8,
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'title',
                className: 'case-wrapper-comp-title title-1',
                children: '持续推出',
              },
            ],
          },
        },
      ],
    },
  }

  export const Part8DataSource = {
    wrapper: { className: 'home-page-wrapper security-part8-wrapper' },
    page: { className: 'home-page security-part8' },
    OverPack: { playScale: 0.3, className: 'security-part8-content-wrapper' },
    titleWrapper: {
      className: 'security-part8-title-wrapper',
      children: [
        { name: 'title', children: '多种订阅方式', className: 'security-part8-title-h1' },
      ],
    },
    Table: {
      name: 'tabsTitle',
      size: 'default',
      className: 'security-part8-table',
      columns: {
        children: [
          {
            dataIndex: 'free',
            key: 'free',
            name: 'free',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '永久授权',
                },
              ],
            },
          },
          {
            dataIndex: 'basic',
            key: 'basic',
            name: 'basic',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '订阅授权',
                },
              ],
            },
          },
          {
            dataIndex: 'pro',
            key: 'pro',
            name: 'pro',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '质检包授权',
                },
              ],
            },
          },
          {
            dataIndex: 'unlimited',
            key: 'unlimited',
            name: 'unlimited',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '定制开发',
                },
              ],
            },
          },
        ],
      },
      dataSource: {
        children: [
          {
            name: 'list0',
            children: [
              {
                children: '一次性建设',
                name: 'content0',
                className: 'security-part8-table-content',
              },
              {
                children: '按年采用订阅的方式',
                name: 'content1',
                className: 'security-part8-table-content',
              },
              {
                children: '根据实际检测物料数量',
                name: 'content2',
                className: 'security-part8-table-content',
              },
              {
                children: '根据需求进行定制开发',
                name: 'security-part3',
                className: 'security-part8-table-content',
              },
            ],
          },
        ],
      },
    },
  };
