import React from 'react';
import { Icon } from 'antd';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';


class Part0 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    return (
      <div {...props} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          <div key="content" {...dataSource.content}>
            {dataSource.content.children}
          </div>
        </QueueAnim>

        <TweenOne
          animation={{
            y: '-=20',
            yoyo: true,
            repeat: -1,
            duration: 1000,
          }}
          className="part0-icon"
          key="icon"
        >
          <Icon type="down" />
        </TweenOne>

      </div>
    );
  }
}

export default Part0;
