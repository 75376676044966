import React from 'react';
class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    return (
      
      <div {...currentProps} {...dataSource.wrapper}>
          <div key="img-bg" {...dataSource.image}>
              <img src={dataSource.image.children} alt="img" />
          </div>
          <div {...dataSource.titleAbs}>
            {dataSource.titleAbs.children}
          </div>
          <div className='img-mask'>
          </div>
          <div key="img-abs" {...dataSource.imageAbs}>
              <img src={dataSource.imageAbs.children} alt="img" />
          </div>
      </div>
    );
  }
}
export default Banner;
