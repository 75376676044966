
import React from "react";

export const Part1DataSource = {
    wrapper: { className: ' pv-part1-wrapper' },
    page: { className: 'pv-part1' },
    OverPack: { playScale: 0.3, className: '' },
    childWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanEnergy',
          className: 'title-h1',
        },
        {
          name: 'title',
          children: 'For Solar PV',
          className: 'title-h2',
        },
        {
          name: 'content',
          className: 'title-content pv-part1-title-content',
          children: '提供太阳能光伏EL图像AI质检解决方案',
        },
      ],
    },
    video: {
      className: 'pv-part1-video',
      children: {
        video: process.env.SOURCE_URL+'/pv/intro.mp4',
        image: process.env.SOURCE_URL+'/solarpv-1.png',
      },
    },
    image: {
      className: 'pv-part1-image',
      children:
      process.env.SOURCE_URL+'/solarpv-1.png',
    },
  
}

export const Part2DataSource = {
  wrapper: { className: 'pv-part2-wrapper'},
  page: { className: 'pv-part2 part2-page' },
  content:{
    className:'pv-part2-content',
    children: (
      <div>
        <h3>太阳能光伏前景提取+缺陷识别检测算法</h3>
        <h1>基于深度学习｜专为太阳能光伏EL图像定制</h1>
      </div>
    ),
  },
}

export const Part3DataSource = {
  wrapper: { className: 'home-page-wrapper pv-part3-wrapper'},
  page: { className: 'home-page pv-part3' },
  OverPack: { playScale: 0.3, className: '' },
  content:{
    className:'part2-content',
    children: (
      <div>
        <h1>速度快，又不失精度</h1>
        <h3>百分之99.9的准确率，2秒1个组件图像</h3>
      </div>
    ),
  },

  image: {
    className: 'pv-part3-image',
    children:
    process.env.SOURCE_URL+'/pv/1.png',
  },
  label:[
    {
      name:'title',
      className:'pv-part3-label',
      children:'*使用144版型实测'
    }
  ]
}


export const Part4DataSource = {
  wrapper: { className: 'home-page-wrapper pv-part3-wrapper'},
  page: { className: 'home-page pv-part3' },
  OverPack: { playScale: 0.3, className: '' },
  content:{
    className:'part2-content',
    children: (
      <div>
        <h1>可识别24种复杂版型</h1>
        <h3>泛化性｜持续迭代｜足够轻量</h3>
      </div>
    ),
  },

  image: {
    className: 'pv-part3-image',
    children:
    process.env.SOURCE_URL+'/pv/2.png',
  },
  label:[
    {
      name:'title',
      className:'pv-part3-label',
      children:''
    }
  ]
}



export const Part5DataSource = {
  wrapper: { className: 'home-page-wrapper pv-part5-wrapper' },
  page: { className: 'home-page pv-part5' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '全新的数字化品控服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于CanEnergy For Solar PV',
      },
    ],
  },
  block: {
    className: 'pv-part5-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pv-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'pv-part5-icon',
            children:
            process.env.SOURCE_URL+'/pv/icon/1.png',
          },
          textWrapper: { className: 'pv-part5-text' },
          title: { className: 'pv-part5-title', children: '数据筛选与清洗' },
          content: {
            className: 'pv-part5-content',
            children:
              '统计分类照片类别，细致专业，不浪费素材',
          },
        },
      },
      {
        name: 'block1',
        className: 'pv-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'pv-part5-icon',
            children:
            process.env.SOURCE_URL+'/pv/icon/2.png',
            
          },
          textWrapper: { className: 'pv-part5-text' },
          title: { className: 'pv-part5-title', children: '数据标记' },
          content: {
            className: 'pv-part5-content',
            children:
              '专业可明数字化标记软件，供算法训练',
          },
        },
      },
      {
        name: 'block2',
        className: 'pv-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'pv-part5-icon',
            children:
            process.env.SOURCE_URL+'/pv/icon/3.png',
            
          },
          textWrapper: { className: 'pv-part5-text' },
          title: { className: 'pv-part5-title', children: '模型开发与迭代' },
          content: {
            className: 'pv-part5-content',
            children:
              '自研算法模型，持续更新进步，不失效率',
          },
        },
      },
      {
        name: 'block3',
        className: 'pv-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'pv-part5-icon',
            children:
            process.env.SOURCE_URL+'/pv/icon/4.png',
          },
          textWrapper: { className: 'pv-part5-text' },
          title: { className: 'pv-part5-title', children: '应用软件定制开发' },
          content: {
            className: 'pv-part5-content',
            children:
              '按需求定制开发软件，保证模型的调用效率',
          },
        },
      },
      {
        name: 'block4',
        className: 'pv-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'pv-part5-icon',
            children:  process.env.SOURCE_URL+'/pv/icon/5.png',

          },
          textWrapper: { className: 'pv-part5-text' },
          title: { className: 'pv-part5-title', children: '缺陷结果校验' },
          content: {
            className: 'pv-part5-content',
            children:
              '按不同的质检要求灵活调整算法模型',
          },
        },
      },
      {
        name: 'block5',
        className: 'pv-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'pv-part5-icon',
            children:  process.env.SOURCE_URL+'/pv/icon/6.png',

          },
          textWrapper: { className: 'pv-part5-text' },
          title: { className: 'pv-part5-title', children: '数据分析' },
          content: {
            className: 'pv-part5-content',
            children:
              '按质检要求与研究方向分析数据，提供报表',
          },
        },
      },
    ],
  },
};



export const Part6DataSource = {
  wrapper: { className: ' pv-part6-wrapper' },
  page: { className: 'pv-part6' },
  OverPack: { playScale: 0.3, className: '' },
  childWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '我们的特色：AI + 集成',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-h1',
        children: '专属定制',
      },
    ],
  },
  image: {
    className: 'pv-part6-image',
    children:
    process.env.SOURCE_URL+'/solarpv-1.png',
  },

};


  export const Part10DataSource = {
    wrapper: {
      className: 'case-wrapper-comp-wrapper',
    },
    page: {
      className: 'case-wrapper-comp',
    },
    OverPack: {
      playScale: 0.3,
      className: '',
    },
    titleWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanEnergy系列更多产品',
        },
      ],
    },
    block: {
      className: 'block-wrapper',
      children: [
        {
          name: 'block0',
          className: 'block',
          md: 8,
          href:'/nuclear',
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/energy-1.png',
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '核电运维AI解决方案',
              },
            ],
          },
        },
        {
          name: 'block1',
          className: 'block',
          md: 8,
          xs: 24,
          href:'#',
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/energy-3.png',
  
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '新能源电池包质检',
              },
            ],
          },
        },
        // {
        //   name: 'block2',
        //   className: 'block',
        //   md: 8,
        //   xs: 24,
        //   titleWrapper: {
        //     children: [
        //       {
        //         name: 'title',
        //         className: 'case-wrapper-comp-title title-1',
        //         children: '持续推出',
        //       },
        //     ],
        //   },
        // },
      ],
    },
  }


  export const PriceDataSource = {
    wrapper: { className: 'home-page-wrapper security-part8-wrapper' },
    page: { className: 'home-page security-part8' },
    OverPack: { playScale: 0.3, className: 'security-part8-content-wrapper' },
    titleWrapper: {
      className: 'security-part8-title-wrapper',
      children: [
        { name: 'title', children: '多种订阅方式', className: 'security-part8-title-h1' },
      ],
    },
    Table: {
      name: 'tabsTitle',
      size: 'default',
      className: 'security-part8-table',
      columns: {
        children: [
          {
            dataIndex: 'free',
            key: 'free',
            name: 'free',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '永久授权',
                },
              ],
            },
          },
          {
            dataIndex: 'basic',
            key: 'basic',
            name: 'basic',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '订阅授权',
                },
              ],
            },
          },
          {
            dataIndex: 'pro',
            key: 'pro',
            name: 'pro',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '质检包授权',
                },
              ],
            },
          },
          {
            dataIndex: 'unlimited',
            key: 'unlimited',
            name: 'unlimited',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '定制开发',
                },
              ],
            },
          },
        ],
      },
      dataSource: {
        children: [
          {
            name: 'list0',
            children: [
              {
                children: '一次性建设',
                name: 'content0',
                className: 'security-part8-table-content',
              },
              {
                children: '按年采用订阅的方式',
                name: 'content1',
                className: 'security-part8-table-content',
              },
              {
                children: '根据实际检测物料数量',
                name: 'content2',
                className: 'security-part8-table-content',
              },
              {
                children: '根据需求进行定制开发',
                name: 'security-part3',
                className: 'security-part8-table-content',
              },
            ],
          },
        ],
      },
    },
  };
  