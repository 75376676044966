import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';

class Part3 extends React.PureComponent {

  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.title}>
            {dataSource.title.children}
          </div>
          <OverPack {...dataSource.imageWrapper}>
            <TweenOne
              key="image1"
              animation={{ ...animType.one, delay: 300 }}
              {...dataSource.image1}
            >
              <img src={dataSource.image1.children} width="100%" alt="img" />
            </TweenOne>
            <TweenOne
              key="image2"
              animation={{ ...animType.one, delay: 300 }}
              {...dataSource.image2}
            >
              <img src={dataSource.image2.children} width="100%" alt="img" />
            </TweenOne>
          </OverPack>

          <QueueAnim
            key="QueueAnim"
            type={['bottom', 'top']}
            delay={200}
          >
            <div key="content" {...dataSource.content}>
              {(
                dataSource.content.children
              )}
            </div>

          </QueueAnim>
        </div>
      </div>
    );
  }
}
export default Part3;
