
import React from "react";
export const Part1DataSource = {
  wrapper: { className: ' jetcode-part1-wrapper' },
  page: { className: 'jetcode-part1' },
  OverPack: { playScale: 0.3, className: '' },
  childWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'CanBeauty',
        className: 'title-h1',
      },
      {
        name: 'title',
        children: 'For INKJET Code ',
        className: 'title-h2',
      },
      {
        name: 'content',
        className: 'title-content jetcode-part1-title-content',
        children: '完整的外包装喷码AI质检解决方案',
      },
    ],
  },
  image: {
    className: 'jetcode-part1-image',
    children:
    process.env.SOURCE_URL+'/jetcode/3.png',
  },

};


export const Part2DataSource = {
  wrapper: { className: 'home-page-wrapper jetcode-part2-wrapper'},
  page: { className: 'home-page jetcode-part2' },
  OverPack: { playScale: 0.3, className: '' },
  content:{
    className:'part2-content',
    children: (
      <div>
        <h3>基于深度学习，比只使用OCR技术更具优势</h3>
      </div>
    ),
  },

  image: {
    className: 'jetcode-part2-image',
    children:
    process.env.SOURCE_URL+'/jetcode/1.png',
  }
}

export const Part3DataSource = {
  wrapper: { className: 'home-page-wrapper jetcode-part3-wrapper'},
  page: { className: 'home-page jetcode-part3' },
  OverPack: { playScale: 0.3, className: '' },
  content:{
    className:'part2-content',
    children: (
      <div>
        <h1>满足大部分产线需求</h1>
        <h3>百分之95+的准确率、每分钟50支物料</h3>
      </div>
    ),
  },

  image: {
    className: 'jetcode-part3-image',
    children:
    process.env.SOURCE_URL+'/jetcode/4.png',
  },
}

export const Part4DataSource = {
  wrapper: { className: 'home-page-wrapper jetcode-part4-wrapper'},
  page: { className: 'home-page jetcode-part4' },
  OverPack: { playScale: 0.3, className: '' },
  content:{
    className:'part4-content',
    children: (
      <div>
      <h3>可解决市面上大部分喷码质检问题</h3>
      </div>
    ),
  },

  image: {
    className: 'jetcode-part4-image',
    children:    process.env.SOURCE_URL+'/jetcode/5.png',

  },
}



export const Part5DataSource = {
  wrapper: { className: 'home-page-wrapper jetcode-part5-wrapper' },
  page: { className: 'home-page jetcode-part5' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '全新的数字化品控服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于CanBeauty For INKJET Code',
      },
    ],
  },
  block: {
    className: 'jetcode-part5-block-wrapper',
    children: [

      {
        name: 'block0',
        className: 'jetcode-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'jetcode-part5-icon',
            children:
              process.env.SOURCE_URL+'/pv/icon/6.png',
          },
          textWrapper: { className: 'jetcode-part5-text' },
          title: { className: 'jetcode-part5-title', children: '数据采集' },
          content: {
            className: 'jetcode-part5-content',
            children:
              '依据瓶体材质定制采集设备，兼容多种瓶体',
          },
        },
      },
      {
        name: 'block1',
        className: 'jetcode-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'jetcode-part5-icon',
            children:
              process.env.SOURCE_URL+'/pv/icon/1.png',
          },
          textWrapper: { className: 'jetcode-part5-text' },
          title: { className: 'jetcode-part5-title', children: '数据筛选与清洗' },
          content: {
            className: 'jetcode-part5-content',
            children:
              '统计分类照片类别，细致专业，不浪费素材',
          },
        },
      },
      {
        name: 'block2',
        className: 'jetcode-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'jetcode-part5-icon',
            children:
              process.env.SOURCE_URL+'/pv/icon/2.png',
          },
          textWrapper: { className: 'jetcode-part5-text' },
          title: { className: 'jetcode-part5-title', children: '数据标记' },
          content: {
            className: 'jetcode-part5-content',
            children:
              '专业可明数字化标记软件，供算法训练',
          },
        },
      },
      {
        name: 'block3',
        className: 'jetcode-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'jetcode-part5-icon',
            children:
              process.env.SOURCE_URL+'/pv/icon/3.png',
          },
          textWrapper: { className: 'jetcode-part5-text' },
          title: { className: 'jetcode-part5-title', children: '模型开发与迭代' },
          content: {
            className: 'jetcode-part5-content',
            children:
              '自研算法模型，持续更新进步，不失效率',
          },
        },
      },
      {
        name: 'block4',
        className: 'jetcode-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'jetcode-part5-icon',
            children:
              process.env.SOURCE_URL+'/pv/icon/4.png',
          },
          textWrapper: { className: 'jetcode-part5-text' },
          title: { className: 'jetcode-part5-title', children: '应用软件定制开发' },
          content: {
            className: 'jetcode-part5-content',
            children:
              '按需求定制开发软件，保证模型的调用效率',
          },
        },
      },
      {
        name: 'block5',
        className: 'jetcode-part5-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'jetcode-part5-icon',
            children:
              process.env.SOURCE_URL+'/pv/icon/5.png',
          },
          textWrapper: { className: 'jetcode-part5-text' },
          title: { className: 'jetcode-part5-title', children: '缺陷结果校验' },
          content: {
            className: 'jetcode-part5-content',
            children:
              '按不同的质检要求灵活调整算法模型',
          },
        },
      },
    ],
  },
};


export const Part6DataSource = {
  wrapper: { className: 'home-page-wrapper jetcode-part6-wrapper' },
  page: { className: 'home-page jetcode-part6' },
  childWrapper: {
    className: 'jetcode-part6-text-wrapper',
    children: [
      { name: 'title', children: '依据瓶体材质定制的视觉采集设备', className: 'jetcode-part6-title' },
      {
        name: 'intro',
        className: 'jetcode-part6-intro',
        children: '多重光源相机复合，兼容多种材质瓶体',
      },
      
    ],
  },
  image: {
    className: 'jetcode-part6-image',
    children:
      process.env.SOURCE_URL+'/jetcode/6.png',
  },
};


export const Part56DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      { name: 'title', children: '依据瓶体材质定制的视觉采集设备', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: '多重光源相机复合，兼容多种材质瓶体',
      },
      
    ],
  },
  image: {
    className: 'banner5-image',
    children:
      process.env.SOURCE_URL+'/jetcode/6.png',
  },
};
export const Part7DataSource = {
  wrapper: { className: 'home-page-wrapper jetcode-part7-wrapper' },
  page: { className: 'home-page jetcode-part7' },
  childWrapper: {
    className: 'jetcode-part7-text-wrapper',
    children: [
      // { name: 'title', children: '黑金刚2.0', className: 'banner5-title' },
      {
        name: 'intro',
        className: 'jetcode-part7-intro',
        children: '兼容主流的自动化剔除设备',
      },
      
    ],
  },
  image: {
    className: 'jetcode-part7-image',
    children:
      process.env.SOURCE_URL+'/jetcode/7.png',
  },
};
export const Part57DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper-1',
    children: [
      // { name: 'title', children: '黑金刚2.0', className: 'banner5-title' },
      {
        name: 'explain',
        className: 'banner5-explain-1',
        children: '兼容主流的自动化剔除设备',
      },
      
    ],
  },
  image: {
    className: 'banner5-image-1',
    children:
      process.env.SOURCE_URL+'/jetcode/7.png',
  },
};


  export const Part10DataSource = {
    wrapper: {
      className: 'case-wrapper-comp-wrapper',
    },
    page: {
      className: 'case-wrapper-comp',
    },
    OverPack: {
      playScale: 0.3,
      className: '',
    },
    titleWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanBeauty系列更多产品',
        },
      ],
    },
    block: {
      className: 'block-wrapper',
      children: [
        {
          name: 'block0',
          className: 'block',
          md: 8,
          xs: 24,
          href:'/lipstick',
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/lipstick-img-1.png',
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '口红产线质检',
              },
            ],
          },
        },
        {
          name: 'block1',
          className: 'block',
          md: 8,
          xs: 24,
          href:'/csar',
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/jetcode/2.png',
  
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: 'CSAR合规辅助软件',
              },
            ],
          },
        },
        // {
        //   name: 'block2',
        //   className: 'block',
        //   md: 8,
        //   xs: 24,
        //   titleWrapper: {
        //     children: [
        //       {
        //         name: 'title',
        //         className: 'case-wrapper-comp-title title-1',
        //         children: '持续推出',
        //       },
        //     ],
        //   },
        // },
      ],
    },
  }

  export const Part8DataSource = {
    wrapper: { className: 'home-page-wrapper jetcode-part8-wrapper' },
    page: { className: 'home-page jetcode-part8' },
    OverPack: { playScale: 0.3, className: 'jetcode-part8-content-wrapper' },
    titleWrapper: {
      className: 'jetcode-part8-title-wrapper',
      children: [
        { name: 'title', children: '多种订阅方式', className: 'jetcode-part8-title-h1' },
      ],
    },
    Table: {
      name: 'tabsTitle',
      size: 'default',
      className: 'jetcode-part8-table',
      columns: {
        children: [
          {
            dataIndex: 'free',
            key: 'free',
            name: 'free',
            childWrapper: {
              className: 'jetcode-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'jetcode-part8-table-name',
                  children: '永久授权',
                },
              ],
            },
          },
          {
            dataIndex: 'basic',
            key: 'basic',
            name: 'basic',
            childWrapper: {
              className: 'jetcode-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'jetcode-part8-table-name',
                  children: '订阅授权',
                },
              ],
            },
          },
          {
            dataIndex: 'pro',
            key: 'pro',
            name: 'pro',
            childWrapper: {
              className: 'jetcode-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'jetcode-part8-table-name',
                  children: '质检包授权',
                },
              ],
            },
          },
          {
            dataIndex: 'unlimited',
            key: 'unlimited',
            name: 'unlimited',
            childWrapper: {
              className: 'jetcode-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'jetcode-part8-table-name',
                  children: '定制开发',
                },
              ],
            },
          },
        ],
      },
      dataSource: {
        children: [
          {
            name: 'list0',
            children: [
              {
                children: '一次性建设',
                name: 'content0',
                className: 'jetcode-part8-table-content',
              },
              {
                children: '按年采用订阅的方式',
                name: 'content1',
                className: 'jetcode-part8-table-content',
              },
              {
                children: '根据实际检测物料数量',
                name: 'content2',
                className: 'jetcode-part8-table-content',
              },
              {
                children: '根据需求进行定制开发',
                name: 'content3',
                className: 'jetcode-part8-table-content',
              },
            ],
          },
        ],
      },
    },
  };
  