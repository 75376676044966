import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { enquireScreen } from 'enquire-js';


import Header from './layout/Header';
import Footer1 from './layout/Footer';
import Connect from './page/Connect'
import Home from './page/Home';
import PageBeauty from './page/Beauty'
import PageLipstick from './page/Lipstick'
import PageEnergy from './page/Energy'
import PageSolarPV from './page/SolarPV'
import PageJETCode from './page/JETCode'
import PageCSAR from './page/CSAR'
import PageSecurity from './page/Security'
import PageNuclear from './page/Nuclear'

import {
  Nav00DataSource,
  Footer11DataSource
} from './layout/data.source.js';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }
  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    return (
      <Router>
        <div>
          <Header dataSource={Nav00DataSource} isMobile={this.state.isMobile} />
          <Route exact path="/" component={Home} />
          <Route path="/canbeauty" component={PageBeauty} />
          <Route path="/contact" component={Connect} />
          <Route path="/lipstick" component={PageLipstick} />
          <Route path="/energy" component={PageEnergy} />
          <Route path="/solarpv" component={PageSolarPV} />
          <Route path="/jetcode" component={PageJETCode} />
          <Route path="/csar" component={PageCSAR} />
          <Route path="/security" component={PageSecurity} />
          <Route path="/nuclear" component={PageNuclear} />
          
          <Footer1
            dataSource={Footer11DataSource}
            isMobile={this.state.isMobile}
          />
          {/* <Footer2 
            isMobile={this.state.isMobile}
            dataSource={Footer21DataSource}
          /> */}
        </div>
      </Router>
    );
  }
}
export default App;