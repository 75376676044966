import React from 'react';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Button } from 'antd';
import { getChildrenToRender } from '@/utils/utils';

class Part0 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }


  render() {
    const { ...tagProps } = this.props;
    const { dataSource, } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}

          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
            <Button>
              <a href="/contact?target=jetcode">
                申请演示
              </a>
            </Button>
          </QueueAnim>

          <OverPack {...dataSource.OverPack}>
            <TweenOne
              key="video"
              animation={{ ...animType.one, delay: 300 }}
              {...dataSource.image}
            >

              <img src={dataSource.image.children} width="100%" alt="img" />
            </TweenOne>
          </OverPack>

        </div>


      </div>
    );
  }
}

export default Part0;
