import React from 'react';
import './style.less';

import { findDOMNode } from 'react-dom';
import TweenOne from 'rc-tween-one';
import { Menu } from 'antd';
import { Link } from "react-router-dom";
const { SubMenu } = Menu;
const Item = Menu.Item;
const whitePath = ["lipstick","contact","energy","solarpv","jetcode","csar","security"];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.rootSubmenuKeys = [];
    this.state = {
      phoneOpen: false,
      menuHeight: 0,
      // change: true,
      openKeys:[],
      defaultKey:window.location.pathname,
    };
    this.change = true;
  }
  

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ 
        openKeys,

        menuHeight: 0,
      });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  phoneClick = () => {
    const menu = findDOMNode(this.menu);
    const phoneOpen = !this.state.phoneOpen;
    this.setState({ openKeys:[] });
    window.scrollTo(0, 0)

    this.setState({
      phoneOpen,
      menuHeight: phoneOpen ? menu.scrollHeight : 0,
    });
  };
  checkPathToChangeNav = () =>{
    const header = document.getElementById('header');
    if (whitePath.includes(window.location.pathname.replace(/\//g,''))) {
      // this.setState({change:false});
      // this.state.change = false;
      this.change = false;
      header.classList.add('nav-header-change');
    } else {
      const visiableHeight = document.documentElement.clientHeight, // 可视区域高度
      currentOffset = document.documentElement.scrollTop; // 滚动的距离
      if (currentOffset < visiableHeight-64) {
      // this.state.change = true;
      // this.setState({change:true});
      this.change = true;
      header.classList.remove('nav-header-change');
      }
    }
  }

  componentDidMount() {
    this.checkPathToChangeNav()
    window.addEventListener('scroll', (e) => {
      const header = document.getElementById('header');
      const visiableHeight = document.documentElement.clientHeight, // 可视区域高度
        currentOffset = document.documentElement.scrollTop; // 滚动的距离

      if (currentOffset > visiableHeight-64) {
        header.classList.add('nav-header-change');
      } else if (this.change) {
      // } else if (this.state.change) {
        header.classList.remove('nav-header-change');
      }
    })
  };

  componentDidUpdate() {

    this.checkPathToChangeNav()
  };

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const { menuHeight, phoneOpen,defaultKey } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = Object.keys(navData).map((key, i) => {

      let node;
      if (!navData[key].children) {
        // !this.rootSubmenuKeys.includes(navData[key].href) && this.rootSubmenuKeys.push(navData[key].href);
        node = (

          <Item key={navData[key].href} className={i.toString()}>

            <Link
              {...navData[key]}
              to={navData[key].href}
              target={navData[key].target}
              onClick={() => {
                this.phoneClick();
              }}
            >
              {navData[key].title}
            </Link>

          </Item>
        )
      } else {
        const subNode = navData[key].children;
        !this.rootSubmenuKeys.includes(navData[key].href) && this.rootSubmenuKeys.push(navData[key].href);

        const subMenu = Object.keys(navData[key].children).map((skey, si) =>{ 
          return (
            <Item key={subNode[skey].href} >
              <Link
                {...subNode[skey]}
                to={subNode[skey].href}
                target={subNode[skey].target}
                onClick={() => {
                  this.phoneClick();
                }}
              >
                {subNode[skey].title}
              </Link>
            </Item>
          )
        });
        node = (
          <SubMenu key={navData[key].href} title={navData[key].title} theme={'dark'}>
            {subMenu}
          </SubMenu>
        )
      }
      return node;
    });

    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: 'from' }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? ' open' : ''}`}
        >
          <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
            {...dataSource.logo}
          >
            <img width="100%" src={dataSource.logo.children} alt="img" />
          </TweenOne>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne
            {...dataSource.Menu}
            animation={{ x: 30, type: 'from', ease: 'easeOutQuad' }}
            ref={(c) => {
              this.menu = c;
            }}
            style={isMobile ? { height: menuHeight } : null}
          >
            <Menu
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
              mode={isMobile ? 'inline' : 'horizontal'}
              defaultSelectedKeys={[defaultKey]}
              theme={isMobile ? 'dark' : 'default'}
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    );
  }
}

export default Header;
