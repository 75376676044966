import React from 'react';
import { enquireScreen } from 'enquire-js';


import Part0 from './Part0';
import Part1 from './Part1';
import Part2 from './Part2';
import Part3 from './Part3';
import Part4 from './Part4';
import Part5 from './Part5';
import Part6 from './Part6';
import Part7 from './Part7';
import Part8 from './Part8';
// import Part9 from './Part9';
import Part9 from '@/components/caseWrapper'

import {
  Part0DataSource,
  Part1DataSource,
  Part2DataSource,
  Part3DataSource,
  Part4DataSource,
  Part5DataSource,
  Part6DataSource,
  Part7DataSource,
  Part8DataSource,
  Part9DataSource
} from './data.source.js';

import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

const location = window.location;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    // dva 2.0 样式在组件渲染之后动态加载，导致滚动组件不生效；线上不影响；
    /* 如果不是 dva 2.0 请删除 start */
    if (location.port) {
      // 样式 build 时间在 200-300ms 之间;
      setTimeout(() => {
        this.setState({
          show: true,
        });
      }, 500);
    }
    /* 如果不是 dva 2.0 请删除 end */
  }

  render() {
    const children = [
      <Part0
        id="part0"
        key="part0"
        dataSource={Part0DataSource}
        isMobile={this.state.isMobile}
      />,
      <Part1
        id="part1"
        key="part1"
        dataSource={Part1DataSource}
        isMobile={this.state.isMobile}
      />, 
      <Part2
        id="part2"
        key="part2"
        dataSource={Part2DataSource}
        isMobile={this.state.isMobile}
      />,
      <Part3
        id="part3"
        key="part3"
        dataSource={Part3DataSource}
        isMobile={this.state.isMobile}
      />,
      <Part4
        id="part4"
        key="part4"
        dataSource={Part4DataSource}
        isMobile={this.state.isMobile}
      />,
      <Part5
        id="part5"
        key="part5"
        dataSource={Part5DataSource}
        isMobile={this.state.isMobile}
      />,
      <Part6
        id="part6"
        key="part6"
        dataSource={Part6DataSource}
        isMobile={this.state.isMobile}
      />,
      <Part7
        id="part7"
        key="part7"
        dataSource={Part7DataSource}
        isMobile={this.state.isMobile}
      />,
      <Part8
        id="part8"
        key="part8"
        dataSource={Part8DataSource}
        isMobile={this.state.isMobile}
      />,
      <Part9
        id="part9"
        key="part9"
        dataSource={Part9DataSource}
        isMobile={this.state.isMobile}
      />
    ];
    return (
      <div
        className="lipstick-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {this.state.show && children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
