import React from 'react';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row,Col } from 'antd';
import { getChildrenToRender } from '@/utils/utils';



class Part3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>

        <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
        </div>

        <Row {...dataSource.page}>
          <Col {...dataSource.image.col}>
          <OverPack {...dataSource.OverPack}>
            <TweenOne
              key="video"
              animation={{ ...animType.one, delay: 300 }}
              {...dataSource.image}
            >
              <img src={dataSource.image.children} width="100%" alt="img" />
            </TweenOne>
          </OverPack>
          </Col>
          <Col {...dataSource.childWrapper.col}>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}
          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
          </QueueAnim>
          </Col>
        </Row>
        
      </div>
    );
  }
}

export default Part3;
