import React from 'react';

export const Part0DataSource = {
  wrapper: { className: 'part0 part-page-wrapper'},
  textWrapper: { className:'part0-text-wrapper'},
  content:{
    className:'part0-content',
    children: (
      <div>
        <h3>CAN BEAUTY系列</h3>
        <h1>为AI+美妆而生 让美更美</h1>
      </div>
    ),
  },
}

export const Part1DataSource = {
  wrapper: { className: 'home-page-wrapper beauty-part1' },
  page: { className: 'home-page beauty-part1-page' },
  childWrapper: {
    className: 'beauty-part1-title-wrapper',
    children: [
      { name: 'title', children: 'CanBeauty ', className: 'beauty-part1-title color-0' },
      { name:'title',children:'For Lipstick', className:'beauty-part1-title-sub color-0' },
      {
        name: 'content',
        className: 'beauty-part1-content',
        children: '完整的口红产线AI质检产品',
      },
      {
        name: 'a',
        className:'beauty-part1-a color-0',
        href: '/lipstick',
        children: '进一步了解 >',//{ href: '#', className:'banner4-a', children: '进一步了解 >' },
      },
    ],
  },
  image: {
    className: 'beauty-part1-image',
    children:
      process.env.SOURCE_URL+'/lipstick-prod.png',
  },
};

export const Part2DataSource = {
  wrapper: { className: 'home-page-wrapper beauty-part2-wrapper' },
  page: { className: 'home-page beauty-part2' },
  OverPack: { playScale: 0.3, className: '' },
  childWrapper: {
      className: 'beauty-part2-text-wrapper',
      children: [
          {
              name: 'title',
              children: 'CanBeauty',
              className: 'beauty-part2-title',
          },
          {
              name: 'content',
              className: 'beauty-part2-content',
              children: 'For Bottle Coding',
          },
          {
              name: 'intro',
              className: 'beauty-part2-intro',
              children: '利用深度学习完成喷码检测',
          },
          {
            name:'a',
            className:'beauty-part2-a',
            href:'/jetcode',
            children:'进一步了解 >'
          }
      ],
  },
  image: {
      className: 'beauty-part2-image',
      children:
          process.env.SOURCE_URL+'/jetcode/1.png',
  },

};

export const Part3DataSource = {
  wrapper: { className: 'home-page-wrapper beauty-part3-wrapper' },
  page: { className: 'home-page beauty-part3' },
  OverPack: { playScale: 0.3, className: '' },
  childWrapper: {
      className: 'beauty-part3-text-wrapper',
      children: [
          {
              name: 'title',
              children: 'CanBeauty',
              className: 'beauty-part3-title',
          },
          {
              name: 'content',
              className: 'beauty-part3-content',
              children: 'For CSAR',
          },
          {
              name: 'intro',
              className: 'beauty-part3-intro',
              children: '一套致力于提高工作效率,降低人工复检强度的办公辅助软件',
          },
          {
            name:'a',
            className:'beauty-part3-a',
            href:'/csar',
            children:'进一步了解 >'
          }
      ],
  },
  image: {
      className: 'beauty-part3-image',
      children:
          process.env.SOURCE_URL+'/jetcode/2.png',
  },

};


export const Part4DataSource = {
  wrapper: { className: 'part4 part4-page-wrapper'},
  textWrapper: { className:'part4-text-wrapper'},
  content:{
    className:'part4-content',
    children: (
      <div>
        <h1>定制更多美妆科技需求</h1>
        <h3>随时联系我们</h3>
      </div>
    ),
  },
}

