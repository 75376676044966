
import React from "react";
export const Part1DataSource = {
    h3: "CAN ENERGY系列",
    h1: "打造AI+能源的新名片",
    bg: process.env.SOURCE_URL+"/energy-2.png",
    color: '#000'
}
export const Part2DataSource = {
    wrapper: { className: 'home-page-wrapper banner-wrapper-comp' },
    page: { className: 'home-page banner-wrapper-comp-page' },
    childWrapper: {
      className: 'banner-wrapper-comp-title-wrapper',
      children: [
        { name: 'title', children: 'CanEnergy', className: 'banner-wrapper-comp-title color-0' },
        { name: 'title', children: 'For Solar PV', className: 'banner-wrapper-comp-title-2 color-0' },
        {
          name: 'content',
          className: 'banner-wrapper-comp-content',
          children: '提供太阳能光伏EL图像AI质检解决方案',
        },
        {
          name: 'a',
          className:'banner-wrapper-comp-a color-0',
          href: '/solarpv',
          children: '进一步了解 >',//{ href: '#', className:'banner4-a', children: '进一步了解 >' },
        },
      ],
    },
    image: {
      className: 'banner-wrapper-comp-image',
      children:  process.env.SOURCE_URL+'/solarpv-1.png',

    },
  };
  

export const Part22DataSource = {
    wrapper: { className: 'home-page-wrapper banner-wrapper-comp' },
    page: { className: 'home-page banner-wrapper-comp-page' },
    childWrapper: {
        className: 'banner-wrapper-comp-title-wrapper',
        children: [
            {

                name: 'title', children: 'CanEnergy For Solar PV', className: 'banner-wrapper-comp-title color-1'
            },
            {
                name: 'content',
                className: 'banner-wrapper-comp-content',
                children: 'CanEnergy For Solar PV',
            },
            {
                name: 'a',
                className: 'banner-wrapper-comp-a color-1',
                children: '进一步了解 >',//{ href: '#', className:'banner4-a', children: '进一步了解 >' },
            },
        ],
    },
    image: {
        className: 'banner-wrapper-comp-image',
        children:
            process.env.SOURCE_URL+'/energy-1.png',
    },
}


export const Part3DataSource = {
    wrapper: { className: 'home-page-wrapper energy-part3-wrapper' },
    page: { className: 'home-page energy-part3' },
    OverPack: { playScale: 0.3, className: '' },
    childWrapper: {
        className: 'energy-part3-text-wrapper',
        children: [
            {
                name: 'title',
                children: 'CanEnergy',
                className: 'energy-part3-title',
            },
            {
                name: 'content',
                className: 'energy-part3-content',
                children: 'For Nuclear Power operation and maintenance',
            },
            {
                name: 'intro',
                className: 'energy-part3-intro',
                children: '提供核电运维AI解决方案',
            },
            {
              name:'a',
              className:'energy-part4-a',
              href:'/nuclear',
              children:'进一步了解 >'
            }
        ],
    },
    image: {
        className: 'energy-part3-image',
        children:
            process.env.SOURCE_URL+'/energy-1.png',
    },

};


export const Part4DataSource = {
    wrapper: { className: 'home-page-wrapper energy-part4-wrapper' },
    page: { className: 'home-page energy-part4' },
    OverPack: { playScale: 0.3, className: '' },
    childWrapper: {
        className: 'energy-part4-text-wrapper',
        children: [
            {
                name: 'title',
                children: 'CanEnergy',
                className: 'energy-part4-title',
            },
            {
                name: 'content',
                className: 'energy-part4-content',
                children: 'For Battery pack',
            },
            {
                name: 'intro',
                className: 'energy-part4-intro',
                children: '提供新能源电池包质检解决方案',
            },
            {
              name:'a',
              className:'energy-part4-a',
              href:'#',
              children:'进一步了解 >'
            }
        ],
    },
    image: {
        className: 'energy-part4-image',
        children:
            process.env.SOURCE_URL+'/energy-3.png',
    },

};



export const Part5DataSource = {
    wrapper: {
        className: 'home-page-wrapper energy-part5-wrapper',
    },
    page: {
        className: 'home-page energy-part5',
    },
    OverPack: {
        playScale: 0.3,
        className: '',
    },
    block: {
        className: 'block-wrapper',
        children: [
            {
                name: 'block0',
                className: 'block',
                md: 12,
                xs: 24,
                titleWrapper: {
                    children: [
                        {
                            name: 'image',
                            className: 'energy-part5-image',
                            children: process.env.SOURCE_URL+'/energy-3.png',
                        },
                        {
                            name: 'title',
                            className: 'energy-part5-title',
                            children: '提供新能源电池包质检解决方案',
                        },

                        {
                            name: 'content1',
                            className: 'energy-part5-content',
                            children: '进一步了解 >',
                        },
                        {
                            name: 'inner',
                            className: 'energy-part5-inner-title',
                            children: 'CanEnergy'
                        },
                        {
                            name: 'inner',
                            className: 'energy-part5-inner-title-1',
                            children: 'For Battery pack'
                        }
                    ],
                },
            },
            {
                name: 'block1',
                className: 'block',
                md: 12,
                xs: 24,
                titleWrapper: {
                    children: [
                        {
                            name: 'image',
                            className: 'energy-part5-image',
                            children: process.env.SOURCE_URL+'/energy-1.png',

                        },
                        {
                            name: 'title',
                            className: 'energy-part5-title',
                            children: '提供核电运维AI解决方案',
                        },
                        {
                            name: 'content1',
                            className: 'energy-part5-content',
                            children: '进一步了解 >',
                        }, {
                            name: 'inner',
                            className: 'energy-part5-inner-title',
                            children: 'CanEnergy'
                        },
                        {
                            name: 'inner',
                            className: 'energy-part5-inner-title-2',
                            children: 'For Nuclear power operation and maintenance'
                        }
                    ],
                },
            },
        ],
    },
}

export const Part6DataSource = {
    wrapper: { className: 'energy-part6-wrapper' },
    page: { className: 'energy-part6-page' },
    h1: "更多AI+能源的需求，随时联系我们",
    bg: process.env.SOURCE_URL+"/energy-4.png",
    color: '#FFF'
}

export const Part7DataSource = {
    wrapper: { className: 'energy-part7-wrapper' },
    page: { className: 'energy-part7' },
    OverPack: { playScale: 0.3, className: '' },
    childWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: 'CanEnergy',
                className: 'title',
            },
            {
                name: 'sub-title',
                className: 'sub-title',
                children: 'For Nuclear power operation and maintenance',
            },
            {
                name: 'content',
                className: 'title-content',
                children: '提供核电运维AI解决方案',
            },
        ],
    },
};


export const Part8DataSource = {
    wrapper: { className: 'home-page-wrapper energy-part8-wrapper' },
    page: { className: 'home-page energy-part8' },
    OverPack: { playScale: 0.3, className: '' },
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: '核电检测',
            },
        ],
    },
    childWrapper: {
        col: {
            md: 9,
            xs: 24,
        },
        className: 'energy-part8-text-wrapper',

        children: [
            {
                name: 'title',
                children: '检测对象：',
                className: 'energy-part8-title',
            },
            {
                name: 'content',
                className: 'energy-part8-content',
                children: '反应容器及顶盖的焊缝裂缝',
            },
            {
                name: 'title',
                children: '末端执行器：',
                className: 'energy-part8-title',
            },
            {
                name: 'content',
                className: 'energy-part8-content',
                children: '带有超声功能的扫查器',
            },
            {
                name:'title',
                children: '控制方式：',
                className:'energy-part8-title energy-part8-title-bottom'
            },
            {
                name:'content',
                children: '1. 在RobCAD中规划仿真运动路径',
                className:'energy-part8-intro'
            },
            {
                name:'content',
                children: '2. 基于TCP/IP协议发送运动指令',
                className:'energy-part8-intro'
            },
        ],
    },
    image: {
        col: {
            md: 15,
            xs: 24,
        },
        className: 'energy-part8-image',
        children:
            process.env.SOURCE_URL+'/energy-5.png',
    },

};

export const Part9DataSource = {
    wrapper: { className: 'energy-part9-wrapper'},
    page: { className: 'energy-part9' },
    OverPack: { playScale: 0.3, className: '' },
    content:{
      className:'energy-part9-content',
      children: (
        <div>
          <h1>核燃料组件水下精准测量</h1>
        </div>
      ),
    },
    image: {
      className: 'energy-part9-image',
      children:
      process.env.SOURCE_URL+'/energy-6.png',
    },
  }

  export const Part10DataSource = {
    wrapper: {
      className: 'case-wrapper-comp-wrapper',
    },
    page: {
      className: 'case-wrapper-comp',
    },
    OverPack: {
      playScale: 0.3,
      className: '',
    },
    titleWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanEnergy系列更多产品',
        },
      ],
    },
    block: {
      className: 'block-wrapper',
      children: [
        {
          name: 'block0',
          className: 'block',
          md: 8,
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/energy-7.png',
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '太阳能板EL图像质检',
              },
            ],
          },
        },
        {
          name: 'block1',
          className: 'block',
          md: 8,
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/energy-3.png',
  
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '新能源电池包质检',
              },
            ],
          },
        },
        {
          name: 'block2',
          className: 'block',
          md: 8,
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'title',
                className: 'case-wrapper-comp-title title-1',
                children: '持续推出',
              },
            ],
          },
        },
      ],
    },
  }