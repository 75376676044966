
import React from "react";

export const Part1DataSource = {
    wrapper: { className: 'nuclear-part1-wrapper' },
    page: { className: 'nuclear-part1' },
    OverPack: { playScale: 0.3, className: '' },
    childWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: 'CanEnergy',
                className: 'title',
            },
            {
                name: 'sub-title',
                className: 'sub-title',
                children: 'For Nuclear power operation and maintenance',
            },
            {
                name: 'content',
                className: 'title-content',
                children: '提供核电运维AI解决方案',
            },
        ],
    },
};


export const Part2DataSource = {
    wrapper: { className: 'home-page-wrapper nuclear-part2-wrapper' },
    page: { className: 'home-page nuclear-part2' },
    OverPack: { playScale: 0.3, className: '' },
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: '核电检测',
            },
        ],
    },
    childWrapper: {
        col: {
            md: 9,
            xs: 24,
        },
        className: 'nuclear-part2-text-wrapper',

        children: [
            {
                name: 'title',
                children: '检测对象：',
                className: 'nuclear-part2-title',
            },
            {
                name: 'content',
                className: 'nuclear-part2-content',
                children: '反应容器及顶盖的焊缝裂缝',
            },
            {
                name: 'title',
                children: '末端执行器：',
                className: 'nuclear-part2-title',
            },
            {
                name: 'content',
                className: 'nuclear-part2-content',
                children: '带有超声功能的扫查器',
            },
            {
                name:'title',
                children: '控制方式：',
                className:'nuclear-part2-title nuclear-part2-title-bottom'
            },
            {
                name:'content',
                children: '1. 在RobCAD中规划仿真运动路径',
                className:'nuclear-part2-intro'
            },
            {
                name:'content',
                children: '2. 基于TCP/IP协议发送运动指令',
                className:'nuclear-part2-intro'
            },
        ],
    },
    image: {
        col: {
            md: 15,
            xs: 24,
        },
        className: 'nuclear-part2-image',
        children:
            process.env.SOURCE_URL+'/energy-5.png',
    },

};

export const Part3DataSource = {
    wrapper: { className: 'nuclear-part3-wrapper'},
    page: { className: 'nuclear-part3' },
    OverPack: { playScale: 0.3, className: '' },
    content:{
      className:'nuclear-part3-content',
      children: (
        <div>
          <h1>核燃料组件水下精准测量</h1>
        </div>
      ),
    },
    image: {
      className: 'nuclear-part3-image',
      children:
      process.env.SOURCE_URL+'/energy-6.png',
    },
  }

export const Part4DataSource = {
    wrapper: {
      className: 'case-wrapper-comp-wrapper',
    },
    page: {
      className: 'case-wrapper-comp',
    },
    OverPack: {
      playScale: 0.3,
      className: '',
    },
    titleWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanEnergy系列更多产品',
        },
      ],
    },
    block: {
      className: 'block-wrapper',
      children: [
        {
          name: 'block0',
          className: 'block',
          md: 8,
          xs: 24,
          href:'/solarpv',
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/energy-7.png',
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '太阳能板EL图像质检',
              },
            ],
          },
        },
        {
          name: 'block1',
          className: 'block',
          md: 8,
          href:'#',
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/energy-3.png',
  
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '新能源电池包质检',
              },
            ],
          },
        },
        // {
        //   name: 'block2',
        //   className: 'block',
        //   md: 8,
        //   xs: 24,
        //   titleWrapper: {
        //     children: [
        //       {
        //         name: 'title',
        //         className: 'case-wrapper-comp-title title-1',
        //         children: '持续推出',
        //       },
        //     ],
        //   },
        // },
      ],
    },
  }


export const PriceDataSource = {
    wrapper: { className: 'home-page-wrapper security-part8-wrapper' },
    page: { className: 'home-page security-part8' },
    OverPack: { playScale: 0.3, className: 'security-part8-content-wrapper' },
    titleWrapper: {
      className: 'security-part8-title-wrapper',
      children: [
        { name: 'title', children: '多种订阅方式', className: 'security-part8-title-h1' },
      ],
    },
    Table: {
      name: 'tabsTitle',
      size: 'default',
      className: 'security-part8-table',
      columns: {
        children: [
          {
            dataIndex: 'free',
            key: 'free',
            name: 'free',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '永久授权',
                },
              ],
            },
          },
          {
            dataIndex: 'basic',
            key: 'basic',
            name: 'basic',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '订阅授权',
                },
              ],
            },
          },
          {
            dataIndex: 'pro',
            key: 'pro',
            name: 'pro',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '质检包授权',
                },
              ],
            },
          },
          {
            dataIndex: 'unlimited',
            key: 'unlimited',
            name: 'unlimited',
            childWrapper: {
              className: 'security-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'security-part8-table-name',
                  children: '定制开发',
                },
              ],
            },
          },
        ],
      },
      dataSource: {
        children: [
          {
            name: 'list0',
            children: [
              {
                children: '一次性建设',
                name: 'content0',
                className: 'security-part8-table-content',
              },
              {
                children: '按年采用订阅的方式',
                name: 'content1',
                className: 'security-part8-table-content',
              },
              {
                children: '根据实际检测物料数量',
                name: 'content2',
                className: 'security-part8-table-content',
              },
              {
                children: '根据需求进行定制开发',
                name: 'security-part3',
                className: 'security-part8-table-content',
              },
            ],
          },
        ],
      },
    },
  };
  