import React from 'react';
import QueueAnim from 'rc-queue-anim';


class Part4 extends React.PureComponent {
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const bg = process.env.SOURCE_URL+'/color.jpeg'
    return (
      <div {...props} {...dataSource.wrapper}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >

          <div key="content" {...dataSource.content} style={{backgroundImage:`url(${bg})`}}>
            {dataSource.content.children}
          </div>
        </QueueAnim>

      </div>
    );
  }
}

export default Part4;
