import React from 'react';
import QueueAnim from 'rc-queue-anim';


class TextWrapper extends React.PureComponent {
  
  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;

    return (
      <div {...tagProps} {...dataSource.wrapper} style={{backgroundImage: `url(${dataSource.bg})`}}>
         <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.page} 
        >
          <div key="title" >
            <h1 style={{color:dataSource.color}}>{dataSource.h1}</h1>
          </div>

        </QueueAnim> 
      </div>
    );
  }
}
export default TextWrapper;

// const dataSource = {
//     page: { className: 'lipstick-part1 part1-page' },
//     content:{
//       className:'part1-content',
//       children: (
//         <div>
//           <h3>强大的口红识别检测算法</h3>
//           <h1>基于深度学习｜专为口红定制</h1>
//         </div>
//       ),
//     },
//   }