import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Button } from 'antd';
import { getChildrenToRender } from '@/utils/utils';

class Part7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  toConnect = () => {
    
  }


  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <QueueAnim
            key="text"
            type={animType.queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            {...dataSource.childWrapper}

          >
            {dataSource.childWrapper.children.map(getChildrenToRender)}
            <Button onClick={
              this.toConnect
            }>
              <a href="/contact?target=nuclear">
              申请演示
              </a>
            </Button>
          </QueueAnim>


        </div>



      </div>
    );
  }
}

export default Part7;
