import React from 'react';
import QueueAnim from 'rc-queue-anim';

class Part1 extends React.PureComponent {
  
  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;

    return (
      <div {...tagProps} {...dataSource.wrapper}>
         <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.page}
        >
          <div key="title" {...dataSource.content}>
            { (
                dataSource.content.children
              )}
          </div>

        </QueueAnim> 
      </div>
    );
  }
}
export default Part1;
