import React from 'react';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';

class Part3 extends React.PureComponent {
  
  render() {
    const { ...tagProps } = this.props;
    const { dataSource } = tagProps;
    delete tagProps.dataSource;
    delete tagProps.isMobile;
    const animType = {
      queue: 'bottom',
      one: {
        y: '+=30',
        opacity: 0,
        type: 'from',
        ease: 'easeOutQuad',
      },
    };
    return (
      <div {...tagProps} {...dataSource.wrapper}>
        <div {...dataSource.page}>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
        >
          <div key="title" {...dataSource.content}>
            { (
                dataSource.content.children
              )}
          </div>

        </QueueAnim> 
        
        <OverPack {...dataSource.OverPack}>
            <TweenOne
              key="image"
              animation={{ ...animType.one, delay: 300 }}
              {...dataSource.image}
            >
              
              <img src={dataSource.image.children} width="100%" alt="img" />

            </TweenOne>
            
          </OverPack>
        </div>
      </div>
    );
  }
}
export default Part3;
