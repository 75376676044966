export const Contact00DataSource = {
    wrapper: { className: 'home-page-wrapper content10-wrapper' },
    page:{ className:'contact-page' },
    Content: {
      className: 'icon-wrapper',
      children: {
        icon: {
          className: 'icon',
          children:
            'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
          name: '主要图标',
        },
        iconShadow: {
          className: 'icon-shadow',
          children:
            'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
          name: '图标影阴',
        },
        // url: { children: 'https://gaode.com/place/B00155Q7EO', name: '跳转地址' },
        url: { children: 'https://gaode.com/search?query=%E5%92%8C%E5%85%89%E5%A4%A9%E5%9C%B0B%E6%A0%8B601', name: '跳转地址' },
        title: { children: '公司地址', name: '弹框标题' },
        content: {
          children: '可明科技  上海市徐汇区和光天地B栋601',
          name: '弹框内容',
        },
      },
    },
  };

  export const FormDataSource = {
    
    options:{
      job:[
        {
          val:'0',
          text:'工程师'
        },
        {
          val:'1',
          text:'销售经理'
        },
        {
          val:'2',
          text:'产品经理'
        },
        {
          val:'3',
          text:'技术经理'
        },
        {
          val:'4',
          text:'项目经理'
        }
      ],
      prod:[
        {
          val:'0',
          text:'口红质检',
        },
        {
          val:'1',
          text:'太阳能质检',
        },
        {
          val:'2',
          text:'核电运维',
        },
        {
          val:'3',
          text:'CSAR',
        },
        {
          val:'4',
          text:'瓶底质检'
        }
      ],
      companyType:[
        {
          val:'0',
          text:'终端用户'
        },
        {
          val:'1',
          text:'硬件制造商'
        },
        {
          val:'2',
          text:'集成商'
        },
        {
          val:'3',
          text:'其他'
        }
      ],
      trade:[
        {
          val:'0',
          text:'新能源'
        },
        {
          val:'1',
          text:'彩妆'
        },
        {
          val:'2',
          text:'医疗'
        },
        {
          val:'3',
          text:'工业制造'
        },
        {
          val:'4',
          text:'其他'
        }
      ]
    },
  }