
export const Part1DataSource = {
    wrapper: { className: ' csar-part1-wrapper' },
    page: { className: 'csar-part1' },
    OverPack: { playScale: 0.3, className: '' },
    childWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanBeauty',
          className: 'title-h1',
        },
        {
          name: 'title',
          children: 'For CSAR',
          className: 'title-h2',
        },
        {
          name: 'content',
          className: 'title-content csar-part1-title-content',
          children: '一套致力于提高工作效率，降低人工复检强度的办公辅助软件',
        },
      ],
    },
    image: {
      className: 'csar-part1-image',
      children:
      process.env.SOURCE_URL+'/jetcode/2.png',
    },

}

export const Part2DataSource = {
  wrapper: { className: 'home-page-wrapper csar-part2-wrapper' },
  page: { className: 'home-page csar-part2' },
  childWrapper: {
    className: 'csar-part2-title-wrapper',
    children: [
      { name: 'title', children: '— CSAR ( Cosmetic Supervision and Administration Regulation )', className: 'csar-part2-title' },
      {
        name: 'explain',
        className: 'csar-part2-explain',
        children: '根据化妆品监督条例，申请化妆品新原料注册或者进行化妆品新原料备案，为加强对化妆品安全性和功效性的管理，国家食品药品监督管理局要求化妆品生产企业在产品上市前需要对化妆品提供的名称，成分比例及风险物质进行评估上报，评价合格的产品方可上市销售',
      },

    ],
  },
  image: {
    className: 'csar-part2-image',
    children:
      process.env.SOURCE_URL+'/csar/2.png',
  },
};

export const Part3DataSource = {
  wrapper: { className: 'home-page-wrapper csar-part3-wrapper'},
  page: { className: 'home-page csar-part3' },
  imageWrapper: { playScale: 0.3, className: 'csar-part3-image-wrapper' },
  title: {
    className: 'csar-part3-title',
    children:'现状与痛点',
  },
  content:{
    className:'csar-part3-content',
    children: "因突发的政策面的改革，短时间内大量原材料需要集中报备，各企业大量集中人力资源将完成原材料资料的审核及上报的工作，而用人眼复查这些内容不仅费时费力，还需要留心漏检误检的问题，所以还需要安排人员再进行复检，直接导致效率低下等问题。",
  },
  image1: {
    className: 'csar-part3-image1',
    children:      process.env.SOURCE_URL+'/csar/3.png',

  },
  image2: {
    className: 'csar-part3-image2',
    children:      process.env.SOURCE_URL+'/csar/4.png',

  },
}

export const Part4DataSource = {
  wrapper: { className: 'home-page-wrapper csar-part4-wrapper'},
  OverPack: { playScale: 0.3 },
  page: { className: 'home-page csar-part4' },
  imageWrapper: { playScale: 0.3, className: 'csar-part4-image-wrapper' },
  title: {
    className: 'csar-part4-title',
    children:'产品功能',
  },
  content:{
    className:'csar-part4-content',
    children: " 是可明科技推出了一套可明自主开发结合NLP/OCR自研人工智技术,配合RPA协同机器人等工具,并提供一套B/S架构的管理平台,为客户带来以智能化方式解决文档对比的核心痛点问题，并可对处理的结果进行审查及核对，同时支持PDF,Word,扫描件，图片等多种文档的对比，通过协同机器人可自动的完成文件的提取与对比和数据回填写，让对比核心工作更轻松。",
  },
  block:{
    className:"csar-part4-block-wrapper",
    children:[
      {
        name: 'block0',
        className: 'csar-part4-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part4-icon',
            children:
              process.env.SOURCE_URL+'/csar/5.png',
          },
          textWrapper: { className: 'csar-part4-text' },
          title: { className: 'csar-part4-block-title', children: '数据获取' },
        },
      },
      {
        name: 'block1',
        className: 'csar-part4-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part4-icon',
            children:
              process.env.SOURCE_URL+'/csar/6.png',
          },
          textWrapper: { className: 'csar-part4-text' },
          title: { className: 'csar-part4-block-title', children: '文件比对' },
        },
      },
      {
        name: 'block2',
        className: 'csar-part4-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part4-icon',
            children:
              process.env.SOURCE_URL+'/csar/7.png',
          },
          textWrapper: { className: 'csar-part4-text' },
          title: { className: 'csar-part4-block-title', children: '内容识别' },
        },
      },
      {
        name: 'block3',
        className: 'csar-part4-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part4-icon',
            children:
              process.env.SOURCE_URL+'/csar/8.png',
          },
          textWrapper: { className: 'csar-part4-text' },
          title: { className: 'csar-part4-block-title', children: '结果回填' },
        },
      },
    ]
  },
}



export const Part5DataSource = {
  wrapper: { className: 'home-page-wrapper csar-part5-wrapper' },
  page: { className: 'home-page csar-part5' },
  childWrapper: {
    className: 'csar-part5-text-wrapper',
    children: [
      // { name: 'title', children: '— CSAR ( Cosmetic Supervision and Administration Regulation )', className: 'csar-part5-title' },
      {
        name: 'intro',
        className: 'csar-part5-intro',
        children: '本产品主要整合了OCR、NLP等AI技术，能够以弱耦合，即不改变现有软件系统部署的形式跨系统执行，且实现较高的准确率。',
      },

    ],
  },
  image: {
    className: 'csar-part5-image',
    children:
      process.env.SOURCE_URL+'/csar/9.png',
  },
};

export const Part7DataSource = {
  wrapper: { className: 'home-page-wrapper csar-part7-wrapper'},
  OverPack: { playScale: 0.3 },
  page: { className: 'home-page csar-part7' },
  imageWrapper: { playScale: 0.3, className: 'csar-part7-image-wrapper' },
  title: {
    className: 'csar-part7-title',
    children:'应用场景扩展',
  },
  block:{
    className:"csar-part7-block-wrapper",
    children:[
      {
        name: 'block0',
        className: 'csar-part7-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part7-icon',
            children:
              process.env.SOURCE_URL+'/csar/10.png',
          },
          textWrapper: { className: 'csar-part7-text' },
          title: { className: 'csar-part7-block-title',children:[
            {
              name:'scene',
              className:'csar-part7-block-scene',
              children:'瓶底喷码自动识别'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'印刷材料识别校验'
            }
          ] },
        },
      },
      {
        name: 'block1',
        className: 'csar-part7-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part7-icon',
            children:
              process.env.SOURCE_URL+'/csar/11.png',
          },
          textWrapper: { className: 'csar-part7-text' },
          title: { className: 'csar-part7-block-title', children:[
            {
              name:'scene',
              className:'csar-part7-block-scene',
              children:'自动报关、退税'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'发票真伪验证'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'企业工商信息收集'
            }
          ]},
        },
      },
      {
        name: 'block2',
        className: 'csar-part7-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part7-icon',
            children:
              process.env.SOURCE_URL+'/csar/12.png',
          },
          textWrapper: { className: 'csar-part7-text' },
          title: { className: 'csar-part7-block-title', children:[
            {
              name:'scene',
              className:'csar-part7-block-scene',
              children:'订单识别导入'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'SAP入库制单自动化'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'采购入库自动化'
            }
          ] },
        },
      },
      {
        name: 'block3',
        className: 'csar-part7-block',
        md: 6,
        xs: 12,
        children: {
          icon: {
            className: 'csar-part7-icon',
            children:
              process.env.SOURCE_URL+'/csar/13.png',
          },
          textWrapper: { className: 'csar-part7-text' },
          title: { className: 'csar-part7-block-title', children:[
            {
              name:'scene',
              className:'csar-part7-block-scene',
              children:'出车自动化'
            },{
              name:'scene',
              className:'csar-part7-block-scene',
              children:'操作票自动开票'
            }
          ]},
        },
      },
    ]
  },
}
export const Part6DataSource = {
  wrapper: { className: 'home-page-wrapper csar-part6-wrapper'},
  OverPack: { playScale: 0.3 },
  page: { className: 'home-page csar-part6' },
  imageWrapper: { playScale: 0.3, className: 'csar-part6-image-wrapper' },
  title: {
    className: 'csar-part6-title',
    children:'产品特性',
  },
  block:{
    className:"csar-part6-block-wrapper",
    children:[
      {
        name: 'block0',
        className: 'csar-part6-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'csar-part6-icon',
            children:
              process.env.SOURCE_URL+'/csar/10.png',
          },
          textWrapper: { className: 'csar-part6-text' },
          title: { className: 'csar-part6-block-title',children:[
            {
              name:'header',
              className:'csar-part6-block-header',
              children:'识别率高'
            },{
              name:'text',
              className:'csar-part6-block-text',
              children:'采用了计算机视觉深度学习的方法和自然语言处理深度融合技术，形成完备的包含文字识别、字母识别以及符号识别的技术体系。中英文字符准确率达99%，识别准确度达较高水品'
            }
          ] },
        },
      },
      {
        name: 'block1',
        className: 'csar-part6-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'csar-part6-icon',
            children:
              process.env.SOURCE_URL+'/csar/11.png',
          },
          textWrapper: { className: 'csar-part6-text' },
          title: { className: 'csar-part6-block-title', children:[
            {
              name:'header',
              className:'csar-part6-block-header',
              children:'可跨网站填报'
            },{
              name:'text',
              className:'csar-part6-block-text',
              children:'实现企业业务流程自动化和智能辅助，实现数据自动获得极大提高了业务运营效率，已成功用于材料跨网站填报，并有效降低文本处理流程中人为的错误风险，助理企业数字化升级'
            },
          ]},
        },
      },
      {
        name: 'block2',
        className: 'csar-part6-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'csar-part6-icon',
            children:
              process.env.SOURCE_URL+'/csar/12.png',
          },
          textWrapper: { className: 'csar-part6-text' },
          title: { className: 'csar-part6-block-title', children:[
            {
              name:'header',
              className:'csar-part6-block-header',
              children:'文档差异对比'
            },{
              name:'text',
              className:'csar-part6-block-text',
              children:'采用自然语言处理技术、深度学习技术、计算机视觉技术实现自动抽取文档的关键信息，对比文档之间的差异、审核文档潜在风险、识别提取文档中的信息，赋能企业文档流程化、自动化、智能处理'
            }
          ] },
        },
      },
      {
        name: 'block3',
        className: 'csar-part6-block',
        md: 12,
        xs: 24,
        children: {
          icon: {
            className: 'csar-part6-icon',
            children:
              process.env.SOURCE_URL+'/csar/13.png',
          },
          textWrapper: { className: 'csar-part6-text' },
          title: { className: 'csar-part6-block-title', children:[
            {
              name:'header',
              className:'csar-part6-block-header',
              children:'流程定制化、私有化部署'
            },{
              name:'text',
              className:'csar-part6-block-text',
              children:'部署至本地服务器，根据不同的业务流程，设计独特的定制化流程设计开发，并在用户局域网内实现文字识别功能，保障数据私密性。'
            }
          ]},
        },
      },
    ]
  },
}

  export const Part10DataSource = {
    wrapper: {
      className: 'case-wrapper-comp-wrapper',
    },
    page: {
      className: 'case-wrapper-comp',
    },
    OverPack: {
      playScale: 0.3,
      className: '',
    },
    titleWrapper: {
      className: 'title-wrapper',
      children: [
        {
          name: 'title',
          children: 'CanBeauty系列更多产品',
        },
      ],
    },
    block: {
      className: 'block-wrapper',
      children: [
        {
          name: 'block0',
          className: 'block',
          md: 8,
          xs: 24,
          href:'/lipstick',
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/lipstick-img-1.png',
              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '口红产线质检',
              },
            ],
          },
        },
        {
          name: 'block1',
          className: 'block',
          md: 8,
          href:'/jetcode',
          xs: 24,
          titleWrapper: {
            children: [
              {
                name: 'image',
                className: 'case-wrapper-comp-image',
                children: process.env.SOURCE_URL+'/jetcode/1.png',

              },
              {
                name: 'title',
                className: 'case-wrapper-comp-title',
                children: '瓶底喷码检测',
              },
            ],
          },
        },
        // {
        //   name: 'block2',
        //   className: 'block',
        //   md: 8,
        //   xs: 24,
        //   titleWrapper: {
        //     children: [
        //       {
        //         name: 'title',
        //         className: 'case-wrapper-comp-title title-1',
        //         children: '持续推出',
        //       },
        //     ],
        //   },
        // },
      ],
    },
  }

  export const Part8DataSource = {
    wrapper: { className: 'home-page-wrapper csar-part8-wrapper' },
    page: { className: 'home-page csar-part8' },
    OverPack: { playScale: 0.3, className: 'csar-part8-content-wrapper' },
    titleWrapper: {
      className: 'csar-part8-title-wrapper',
      children: [
        { name: 'title', children: '多种订阅方式', className: 'csar-part8-title-h1' },
      ],
    },
    Table: {
      name: 'tabsTitle',
      size: 'default',
      className: 'csar-part8-table',
      columns: {
        children: [
          {
            dataIndex: 'free',
            key: 'free',
            name: 'free',
            childWrapper: {
              className: 'csar-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'csar-part8-table-name',
                  children: '永久授权',
                },
              ],
            },
          },
          {
            dataIndex: 'basic',
            key: 'basic',
            name: 'basic',
            childWrapper: {
              className: 'csar-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'csar-part8-table-name',
                  children: '订阅授权',
                },
              ],
            },
          },
          {
            dataIndex: 'pro',
            key: 'pro',
            name: 'pro',
            childWrapper: {
              className: 'csar-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'csar-part8-table-name',
                  children: '质检包授权',
                },
              ],
            },
          },
          {
            dataIndex: 'unlimited',
            key: 'unlimited',
            name: 'unlimited',
            childWrapper: {
              className: 'csar-part8-table-name-block',
              children: [
                {
                  name: 'name',
                  className: 'csar-part8-table-name',
                  children: '定制开发',
                },
              ],
            },
          },
        ],
      },
      dataSource: {
        children: [
          {
            name: 'list0',
            children: [
              {
                children: '一次性建设',
                name: 'content0',
                className: 'csar-part8-table-content',
              },
              {
                children: '按年采用订阅的方式',
                name: 'content1',
                className: 'csar-part8-table-content',
              },
              {
                children: '根据实际检测物料数量',
                name: 'content2',
                className: 'csar-part8-table-content',
              },
              {
                children: '根据需求进行定制开发',
                name: 'content3',
                className: 'csar-part8-table-content',
              },
            ],
          },
        ],
      },
    },
  };
