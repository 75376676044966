import React from 'react';


export const Part0DataSource = {
  wrapper: { className: ' lipstick-part0-wrapper' },
  page: { className: 'lipstick-part0' },
  OverPack: { playScale: 0.3, className: '' },
  childWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'CanBeauty',
        className: 'title-h1',
      },
      {
        name: 'title',
        children: 'For Lipstick',
        className: 'title-h2',
      },

      {
        name: 'content',
        className: 'title-content lipstick-part0-title-content',
        children: '完整的口红产线AI质检集成产品',
      },
    ],
  },
  video: {
    className: 'lipstick-part0-video',
    children: {
      video: process.env.SOURCE_URL+'/lipstick-video.mp4',
      image: process.env.SOURCE_URL+'/lipstick-img-1.png',
    },
  },
  image: {
    className: 'lipstick-part0-image',
    children:
    process.env.SOURCE_URL+'/lipstick-prod.png',
  },

};

export const Part1DataSource = {
  wrapper: { className: 'lipstick-part1-wrapper'},
  page: { className: 'lipstick-part1 part1-page' },
  content:{
    className:'part1-content',
    children: (
      <div>
        <h3>强大的口红识别检测算法</h3>
        <h1>基于深度学习｜专为口红定制</h1>
      </div>
    ),
  },
}

export const Part2DataSource = {
  wrapper: { className: 'lipstick-part2-wrapper'},
  page: { className: 'lipstick-part2' },
  OverPack: { playScale: 0.3, className: 'lipstick-part2-imgs' },
  content:{
    className:'part2-content',
    children: (
      <div>
        <h1>又快、又精准</h1>
        <h3>百分之99.9的准确率，每分钟133支</h3>
      </div>
    ),
  },
  imageL: {
    className: 'lipstick-part3-image',
    children:
    process.env.SOURCE_URL+'/lipstick-img-2-1.png',
  },
  imageR: {
    className: 'lipstick-part3-image',
    children:
    process.env.SOURCE_URL+'/lipstick-img-2-2.png',
  },
}
export const Part3DataSource = {
  wrapper: { className: 'lipstick-part3-wrapper'},
  page: { className: 'lipstick-part3' },
  OverPack: { playScale: 0.3, className: '' },
  content:{
    className:'part3-content',
    children: (
      <div>
        <h1>更复杂的口红也不在话下</h1>
        <h3>泛化性｜持续迭代｜足够轻量</h3>
      </div>
    ),
  },
  image: {
    className: 'lipstick-part3-image',
    children:
    process.env.SOURCE_URL+'/4.png',
  },
}

export const Part4DataSource = {
  wrapper: { className: 'home-page-wrapper lipstick-part4-wrapper' },
  page: { className: 'home-page lipstick-part4' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '全新的数字化品控服务',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '基于CanBeautyForLipstick',
      },
    ],
  },
  block: {
    className: 'lipstick-part4-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'lipstick-part4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'lipstick-part4-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'lipstick-part4-text' },
          title: { className: 'lipstick-part4-title', children: '数据采集' },
          content: {
            className: 'lipstick-part4-content',
            children:
              '使用定制的拍摄，保证数据可完全使用',
          },
        },
      },
      {
        name: 'block1',
        className: 'lipstick-part4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'lipstick-part4-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'lipstick-part4-text' },
          title: { className: 'lipstick-part4-title', children: '数据筛选与清洗' },
          content: {
            className: 'lipstick-part4-content',
            children:
              '专业的数据团队，不浪费一张照片，足够细致',
          },
        },
      },
      {
        name: 'block2',
        className: 'lipstick-part4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'lipstick-part4-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'lipstick-part4-text' },
          title: { className: 'lipstick-part4-title', children: '数据标记' },
          content: {
            className: 'lipstick-part4-content',
            children:
              '提供专业的标记服务，支持多种数据，使用可明数字化标记软件，对训练集进行标记',
          },
        },
      },
      {
        name: 'block3',
        className: 'lipstick-part4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'lipstick-part4-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
          },
          textWrapper: { className: 'lipstick-part4-text' },
          title: { className: 'lipstick-part4-title', children: '模型开发与迭代' },
          content: {
            className: 'lipstick-part4-content',
            children:
              '自研算法模型，保证模型持续更新迭代，同时不失效率',
          },
        },
      },
      {
        name: 'block4',
        className: 'lipstick-part4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'lipstick-part4-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'lipstick-part4-text' },
          title: { className: 'lipstick-part4-title', children: '应用软件定制开发' },
          content: {
            className: 'lipstick-part4-content',
            children:
              '按用户需求，可定制开发应用软件，同时做到模型的更小打包及快速调用',
          },
        },
      },
      {
        name: 'block5',
        className: 'lipstick-part4-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'lipstick-part4-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'lipstick-part4-text' },
          title: { className: 'lipstick-part4-title', children: '缺陷结果校验' },
          content: {
            className: 'lipstick-part4-content',
            children:
              '区分不同品牌的口红的质检要求，有条件的对缺陷识别算法进行调整，以便于算法缺陷识别的灵活应用',
          },
        },
      },
    ],
  },
};

export const Part5DataSource = {
  wrapper: { className: 'home-page-wrapper lipstick-part5-wrapper' },
  page: { className: 'home-page lipstick-part5' },
  childWrapper: {
    className: 'lipstick-part5-text-wrapper',
    children: [
      { name: 'title', children: '黑金刚2.0', className: 'lipstick-part5-title' },
      {
        name: 'intro',
        className: 'lipstick-part5-intro',
        children: '专为口红定制的视觉设备，仅用一台相机就能捕捉口红的每个细节',
      },

    ],
  },
  image: {
    className: 'lipstick-part5-image',
    children:
      process.env.SOURCE_URL+'/5.png',
  },
};
export const Part6DataSource = {
  wrapper: { className: 'home-page-wrapper lipstick-part6-wrapper' },
  page: { className: 'home-page lipstick-part6' },
  childWrapper: {
    className: 'lipstick-part6-text-wrapper',
    children: [
      // { name: 'title', children: '黑金刚2.0', className: 'banner5-title' },
      {
        name: 'intro',
        className: 'lipstick-part6-intro',
        children: '兼容主流的自动化剔除设备',
      },

    ],
  },
  image: {
    className: 'lipstick-part6-image',
    children:
      process.env.SOURCE_URL+'/6.png',
  },
};

export const Part7DataSource = {
  wrapper: { className: 'home-page-wrapper lipstick-part7-wrapper' },
  page: { className: 'home-page lipstick-part7' },
  OverPack: { playScale: 0.3, className: 'lipstick-part7-content-wrapper' },
  titleWrapper: {
    className: 'lipstick-part7-title-wrapper',
    children: [
      { name: 'title', children: '多种订阅方式', className: 'lipstick-part7-title-h1' },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'lipstick-part7-table',
    columns: {
      children: [
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'lipstick-part7-table-name-block',
            children: [
              {
                name: 'name',
                className: 'lipstick-part7-table-name',
                children: '永久授权',
              },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'lipstick-part7-table-name-block',
            children: [
              {
                name: 'name',
                className: 'lipstick-part7-table-name',
                children: '订阅授权',
              },
            ],
          },
        },
        {
          dataIndex: 'pro',
          key: 'pro',
          name: 'pro',
          childWrapper: {
            className: 'lipstick-part7-table-name-block',
            children: [
              {
                name: 'name',
                className: 'lipstick-part7-table-name',
                children: '质检包授权',
              },
            ],
          },
        },
        {
          dataIndex: 'unlimited',
          key: 'unlimited',
          name: 'unlimited',
          childWrapper: {
            className: 'lipstick-part7-table-name-block',
            children: [
              {
                name: 'name',
                className: 'lipstick-part7-table-name',
                children: '定制开发',
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              children: '一次性建设',
              name: 'content0',
              className: 'lipstick-part7-table-content',
            },
            {
              children: '按年采用订阅的方式',
              name: 'content1',
              className: 'lipstick-part7-table-content',
            },
            {
              children: '质检包授权',
              name: 'content2',
              className: 'lipstick-part7-table-content',
            },
            {
              children: '定制开发',
              name: 'content3',
              className: 'lipstick-part7-table-content',
            },
          ],
        },
      ],
    },
  },
};


export const Part8DataSource = {
  wrapper: { className: ' lipstick-part8-wrapper home-page-wrapper' },
  page: { className: 'lipstick-part8 home-page' },
  OverPack: { playScale: 0.3, className: '' },
  childWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: '我们的特色：AI + 集成',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-h1',
        children: '用实例说话',
      },
    ],
  },
  image: {
    className: 'lipstick-part8-image',
    children:
    process.env.SOURCE_URL+'/lipstick-prod-1.jpeg',
  },

};


export const Part9DataSource = {
  wrapper: {
    className: ' case-wrapper-comp-wrapper',
  },
  page: {
    className: 'case-wrapper-comp',
  },
  OverPack: {
    playScale: 0.3,
    className: '',
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'CanBeauty系列更多产品',
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        href:'/jetcode',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'case-wrapper-comp-image',
              children: process.env.SOURCE_URL+'/jetcode/1.png',
            },
            {
              name: 'title',
              className: 'case-wrapper-comp-title',
              children: '瓶底喷码检测',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        href:'/csar',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'case-wrapper-comp-image',
              children: process.env.SOURCE_URL+'/jetcode/2.png',

            },
            {
              name: 'title',
              className: 'case-wrapper-comp-title',
              children: 'CSAR合规辅助软件',
            },
          ],
        },
      },

      // {
      //   name: 'block2',
      //   className: 'block',
      //   md: 8,
      //   xs: 24,
      //   titleWrapper: {
      //     children: [

      //       {
      //         name: 'title',
      //         className: 'case-wrapper-comp-title title-1',
      //         children: '持续推出',
      //       },
      //     ],
      //   },
      // },
    ],
  },
}
